.projects {
    display: flex;
    justify-content: space-between;
  }
  
  .project {
    width: 45%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .project h3 {
    font-size: 18px;
  }
  
  .project p {
    margin-top: 10px;
  }
  
  .project a {
    display: block;
    margin-top: 10px;
    color: #0366d6;
  }
  